import { template as template_9a4062b72e8d46059f65fde2da18679d } from "@ember/template-compiler";
const WelcomeHeader = template_9a4062b72e8d46059f65fde2da18679d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
